<template>
  <div class="register">
    <img src="@/assets/img_09.png" alt="" class="logo" />
    <div class="bg"></div>
    <div class="form-wrapper">
      <h3 class="title">欢迎注册</h3>
      <p class="text">已有账号 <span @click="goPage('/login')">登录</span></p>
      <div class="type-wrapper">
        <div
          class="type-item"
          :class="{ active: form.type == 1 }"
          @click="form.type = 1"
        >
          加工厂
        </div>
        <div
          class="type-item"
          :class="{ active: form.type == 2 }"
          @click="form.type = 2"
        >
          医院
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <div class="mobile-wrapper">
            <div class="num">+86</div>
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号码"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="agin">
          <el-input
            v-model="form.agin"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="code-wrapper">
            <el-input
              v-model="form.code"
              placeholder="请输入4位短信验证码"
            ></el-input>
            <div class="send-btn" @click="send">{{ sendTxt }}</div>
          </div>
        </el-form-item>
      </el-form>
      <div class="login-btn" @click="submit">注册</div>
      <div class="agreement-box">
        <el-checkbox v-model="checked" />
        <p>
          阅读并接收<span @click="goPage('/agreement')"
            >《和和数据传输平台用户协议》</span
          >
        </p>
      </div>
    </div>
    <div class="tips">
      <a href="https://beian.miit.gov.cn/">沪ICP备2022078906号</a>
      <span>Copyright © 2022 上海和和义齿有限公司</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
        mobile: "",
        password: "",
        agin: "",
        code: "",
        type: 1,
      },
      checked: false,
      rules: {
        name: [{ required: true, message: "请输入机构名称", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 12, message: "长度在 6 到 12 个字", trigger: "blur" },
        ],
        agin: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
          { min: 6, max: 12, message: "长度在 6 到 12 个字", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入4位短信验证码", trigger: "blur" },
        ],
      },
      sendTxt: "发送验证码",
      timer: null,
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    send() {
      if (this.form.mobile) {
        if (this.sendTxt == "发送验证码") {
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.$api.sms({ phone: this.form.mobile }).then();
          this.sendTxt = 60;
          this.timer = setInterval(() => {
            if (this.sendTxt == 1) {
              clearInterval(this.timer);
              this.timer = null;
              this.sendTxt = "发送验证码";
            } else {
              this.sendTxt--;
            }
          }, 1000);
        }
      } else {
        this.$message({
          showClose: true,
          message: "请输入手机号码",
          type: "error",
          duration: 1000,
        });
      }
    },
    goPage(name) {
      this.$router.push({
        path: name,
      });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.$api
              .reg({
                phone: this.form.mobile,
                organization: this.form.name,
                password: this.form.password,
                code: this.form.code,
                user_cate: this.form.type,
              })
              .then((res) => {
                if (res.code == 200) {
                  // localStorage.setItem("_token_", res.data.token);
                  this.$message({
                    type: "success",
                    message: "注册成功，请等待审核后，登录!",
                    showClose: true,
                    duration: 1000,
                  });
                  this.$router.push({
                    path: "/login",
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          } else {
            this.$message({
              showClose: true,
              message: "请勾选“阅读并接收和和数据传输平台用户协议”",
              type: "error",
              duration: 1000,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.register {
  .el-input--medium .el-input__inner {
    background: #d6d7d8;
    height: 42px;
    &::placeholder {
      color: #868e98;
    }
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #2d8cf0;
    border-color: #2d8cf0;
    border: 2px solid #2d8cf0 !important;
  }
  .el-checkbox__inner {
    background: #d6d7d8;
    border: 2px solid #cccdd0 !important;
  }
}
</style>
<style lang="scss" scoped>
.register {
  height: 100vh;
  .logo {
    position: fixed;
    width: 60px;
    height: 60px;
    z-index: 9;
    left: 20px;
    top: 20px;
    object-fit: contain;
  }
  .bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../assets/bg_01.jpg) no-repeat;
    background-size: cover;
  }
  .form-wrapper {
    background: #e8e8e8;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    height: 630px;
    border-radius: 14px;
    padding: 55px 55px 0;
    .title {
      font-size: 36px;
      color: #000;
      line-height: 1.5;
    }
    .type-wrapper {
      display: flex;
      margin-bottom: 15px;
      .type-item {
        cursor: pointer;
        margin-right: 20px;
        font-size: 14px;
        line-height: 1.6;
        color: #4e5969;
        padding: 5px;
        &.active {
          color: #2d8cf0;
          border-bottom: 2px solid #2d8cf0;
        }
      }
    }
    .text {
      line-height: 1.5;
      font-size: 14px;
      color: #000;
      margin-bottom: 5px;
      span {
        padding: 0 4px;
        color: #2d8cf0;
        cursor: pointer;
      }
    }
    .mobile-wrapper {
      display: flex;
      .num {
        line-height: 42px;
        width: 50px;
        text-align: center;
        background: #d6d7d8;
        color: #000;
        font-size: 14px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .code-wrapper {
      display: flex;
      .send-btn {
        line-height: 42px;
        width: 138px;
        text-align: center;
        background: #2d8cf0;
        color: #fff;
        font-size: 14px;
        border-radius: 3px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    .login-btn {
      line-height: 38px;
      text-align: center;
      background: #2d8cf0;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      border-radius: 2px;
      margin-bottom: 20px;
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      line-height: 22px;
      p {
        font-size: 12px;
        color: rgb(29, 33, 41);
        span {
          cursor: pointer;
          color: #2d8cf0;
        }
      }

      span {
        font-size: 12px;
        cursor: pointer;
        color: #2d8cf0;
      }
    }

    .agreement-box {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: rgb(41, 41, 41);
        padding-left: 6px;
        font-size: 12px;
        span {
          color: #2d8cf0;
          cursor: pointer;
        }
      }
    }
  }
  .tips {
    position: fixed;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    color: #afafaf;
    font-size: 12px;
    a {
      font-size: 12px;
      color: #afafaf;
    }
    span {
      font-size: 12px;
      color: #afafaf;
      display: inline-block;
      padding-left: 8px;
    }
  }
}
</style>